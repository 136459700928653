<template>
  <div class="product">

    <div
      class="product-item"
      :class="{'hasBg':item.bgImg,'mb': index == productData.length - 1}"
      :style="{'background-image':'url('+item.bgImg+')'}"
      v-for="(item,index) in productData"
      :key="item.id"
    >
      <img
        :src="item.bgImg"
        alt=""
        style="display:none"
      >
      <!-- <div class="product-item" :class="{'hasBg':item.bgImg,'mb': index == productData.length - 1}" background="https://oss.prefootball.cn/uploads/20220302/1d844abcbe784447a3be881ab350b9321964.png" v-for="(item,index) in productData" :key="item.id"> -->
      <div class="product-item-cont">
        <div
          class="product-item-cont-img"
          v-if="index%2==1"
        >
          <img :src="item.img" />
        </div>
        <div class="product-item-cont-nr">
          <p class="product-item-cont-nr-tit">{{item.serviceType}}</p>
          <p v-html="item.description"></p>
          <p
            class="product-item-cont-nr-more"
            @click="jumpUlr(item)"
            v-if="item.jumpurl"
          >了解更多<img src="../../assets/img/product/Icon_More.png" /></p>
        </div>
        <div
          class="product-item-cont-img"
          v-if="index%2==0"
        >
          <img :src="item.img" />
        </div>
      </div>
    </div>
    <div class="product-bto">
      <div
        class="product-bto-btn"
        @click="goPrice"
      >了解并购买套餐</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "product",
  data() {
    return {
      bgUrl: [
        require("../../assets/img/product/Pic_Bg_CSXL.png"),
        require("../../assets/img/product/Pic_Bg_CSXL.png"),
      ],
      productList: [],
    };
  },
  props: {
    productData: {
      typeof: Array,
      default: [],
    },
  },
  watch: {
    productData(newV) {
      this.productList = newV;
    },
  },
  mounted() {
    this.productList = this.productData;
  },
  methods: {
    goPrice() {
      this.$emit("goPrice");
    },
    jumpUlr(data) {
        if(data.jumpurl=="/purchase"){
            this.bindToZh()
            return
        }
      this.$router.push({
        path: data.jumpurl,
      });
    },
    // 判断是否是智慧
    bindToZh() {
      this.$store.dispatch("checkAuth", {
        id: 37,
        fn: this.hasAuth,
        that: this,
        fail: this.noAuth,
      });
    },

    hasAuth() {
      this.$router.push({
        path: "/purchase",
      });
    },
    noAuth() {
      this.$router.push({
        path: "/unpurchased",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  .product-item {
    height: 620px;
    margin: 0 auto 50px auto;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .product-item-cont {
      width: 1436px;
      margin: 0 auto;
      padding: 13px 0;
      @include flex(row, space-between, center);
      .product-item-cont-nr {
        font-size: 20px;
        color: #888888;
        line-height: 32px;
        width: 520px;
        .product-item-cont-nr-tit {
          margin-bottom: 40px;
          font-size: 46px;
          font-weight: bold;
          color: #333333;
        }
        .product-item-cont-nr-more {
          margin-top: 24px;
          font-size: 20px;
          color: #bb3037;
          display: flex;
          align-items: center;
          line-height: 22px;
          cursor: pointer;

          > img {
            margin-left: 10px;
            width: 20px;
            height: 16px;
          }
        }
      }

      .product-item-cont-img {
        > img {
          width: 774px;
          height: 490px;
        }
      }
    }
  }
  .hasBg {
    .product-item-cont-nr {
      color: #aaaaaa !important;
      .product-item-cont-nr-tit {
        color: #ffffff !important;
      }
      .product-item-cont-nr-more {
        color: #ff545d !important;
      }
    }
  }
  .mb {
    margin-bottom: 0;
  }
  .product-bto {
    background: url("../../assets/img/product/Pic_Bg_LJGM.png") no-repeat;
    background-size: 100% 480px;
    height: 480px;
    @include flex(row, center, center);
    .product-bto-btn {
      background: url("../../assets/img/product/BtnLJGM.png") no-repeat;
      width: 396px;
      height: 130px;
      background-size: 100% 100%;
      line-height: 122px;
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>