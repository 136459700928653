<template>
    <div class="code">
        <el-dialog
        title="提示"
        :visible.sync="dialogStatus"
        center
        width="444px"
        :before-close="closeDialog">
            <div class="code-main">
                <div class="code-main-img">
                    <img :src="imgUrl" alt="" />
                </div>
                
                <p v-if="exchangeBtnStatus == 2">请登录小程序支付并兑换套餐</p>
                <p v-if="exchangeBtnStatus == 3">请登录小程序兑换套餐并补签合同</p>
                <p v-if="exchangeBtnStatus == 4">请登录小程序获取金币并兑换套餐</p>
                <!-- <div class="qrcode img" ref="qrCodeUrl"></div> -->
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props:{
        status:{
            type:Boolean,
            default:false
        },
        exchangeBtnStatus:{ //data: 1存在有效套餐 2有金币未支付3已支付未兑换4没有金币
            type:Number,
            default:null
        }
        
    },
    data(){
        return {
            dialogStatus:false,
            createNativeData:{},
            timer:null,
            imgUrl:'',
            checkOrderId:'',
        }
    },
    watch:{
        status(newV){
            this.dialogStatus = newV;
        },
    },
    created(){
        this.dialogStatus = this.status;
        this.createCode();
        // this.getGoldApplyInfo();
    },
    methods:{
        closeDialog(){
            this.$emit('close')
        },
        createCode(){   //生成二维码
            // let data = {
            //     a:this.$store.state.userInfo.token,
            // };
            let params = {
                scene:`${this.$store.state.userInfo.token}`,
                appletPath:'pages/index/index/index'
            }
            this.api.product.getunlimitedqrcode(params).then((res) => {
                this.imgUrl = res.data;
            });
        },
        
    },
}
</script>
<style scoped lang="scss">
img{
    margin: 0 auto;
}
.code{
    .code-main{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #2D4F93;
        .code-main-img{
            margin:0 auto 7px auto;
            width: 236px;
            height: 183px;
            background: url('../../../assets/img/product/Pic_Tc_Xcx.png') no-repeat;
            background-size:100% 100%;
            display: flex;
            justify-content: center;
            >img{
                margin-top: 49px;
                width:109px;
                height: 109px;
                border-radius: 50%;
            }
        }
        
    }
    
    

}
</style>
<style lang="scss">
.code{
    .el-dialog{
        border-radius: 10px;
        overflow: hidden;
    }
    .el-dialog__header{
        height: 30px;
        background: #B93138;
        // line-height:20px;
        font-size: 18px;
        font-weight: bold;
        .el-dialog__title{
            color: #FFFFFF;
        }

        .el-dialog__headerbtn{
            // line-height:20px;
            
            .el-dialog__close{
                color:#FFFFFF;
            }
        }
    }
}

</style>