<template>
  <div class="purchase">
    <el-dialog
      title="立即购买  立享专属特权"
      :visible.sync="dialogStatus"
      center
      width="40%"
      :before-close="close"
    >
      <div class="purchase-main">
        <div class="purchase-main-row">
          <div class="purchase-main-row-item">
            <span>当前账户</span>
            <span>{{ $store.state.schoolInfo.atteSchool.schoolName }}</span>
          </div>
          <div class="purchase-main-row-item">
            <span>购买时长</span>
            <span>{{ orderInfo.time }}</span>
          </div>
        </div>
        <div class="purchase-main-row">
          <div class="purchase-main-row-item">
            <span>到期时间</span>
            <span>{{ orderInfo.expireTime }}</span>
          </div>
          <div class="purchase-main-row-item">
            <span>实付金币</span>
            <span class="purchase-main-row-item-val"
              >{{ orderInfo.price }}<span>个</span></span
            >
          </div>
        </div>
        <!-- <div class="purchase-main-type">
                    <span class="purchase-main-type-name">支付方式</span>
                    <div class="purchase-main-type-btn">
                        <div class="purchase-main-type-btn-top">
                            <div :class="currentType == item.id ? 'active' :''" v-for="item in typeList" :key="item.id" @click="changeType(item)">{{item.name}}</div>
                        </div>
                        <div class="purchase-main-type-btn-cont" v-show="currentType == 2">
                            <p>开户名：小牛启航体育（北京）有限公司</p>
                            <p>开户行：中国工商银行北京复外支行</p>
                            <p>银行账号：0200048519200831908</p>
                        </div>
                    </div>
                </div> -->
        <div class="purchase-main-buy" @click="goContract()">
          {{ currentType != 3 ? "填写合同并支付" : "填写合同" }}
        </div>
        <!-- <div class="purchase-main-buy" @click="goContract(currentType)" v-else>填写合同</div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      typeList: [
        {
          name: "微信支付",
          id: 1,
        },
        {
          name: "线下支付",
          id: 2,
        },
        // {
        //     name:'请款专用',
        //     id:3
        // },
      ],
      currentType: 1,
      dialogStatus: false,
      packageId: null,
      orderInfo: {},
    };
  },
  watch: {
    status(newV) {
      this.dialogStatus = newV;
    },
    id(newV) {
      this.packageId = newV;
      if (this.packageId) {
        this.getPayInfo();
      }
    },
  },
  mounted() {
    this.packageId = this.id;
    if (this.packageId) {
      this.getPayInfo();
    }
  },
  methods: {
    changeType(data) {
      this.currentType = data.id;
    },
    goContract() {
      this.$emit("paySet"); //1 代表跳转合同    type:1 微信支付 2:线下支付 3  请款专用
    },
    close() {
      this.$emit("close");
    },
    closeDialog() {
      this.$emit("close");
    },
    getPayInfo() {
      console.log(this.packageId);
      let params = {
        versionId: this.packageId,
      };
      this.api.product.getPayInfo(params).then((res) => {
        this.orderInfo = res.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.purchase {
  .purchase-main {
    .purchase-main-row {
      @include flex(row, space-between, center);
      .purchase-main-row-item {
        margin-bottom: 20px;
        flex: 1;
        font-size: 16px;
        color: #888888;

        > span:nth-child(2) {
          padding-left: 20px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .purchase-main-row-item-val {
          font-size: 28px !important;
          font-weight: bold;
          color: #b93138 !important;
          > span {
            padding-left: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    .purchase-main-type {
      @include flex(row, flex-start, flex-start);
      .purchase-main-type-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
      }
      .purchase-main-type-btn {
        margin-left: 20px;
        .purchase-main-type-btn-top {
          @include flex(row, flex-start, center);

          > div {
            margin-right: 20px;
            width: 140px;
            height: 40px;
            line-height: 40px;
            background: #ffffff;
            border: 1px solid #395da4;
            border-radius: 20px;
            text-align: center;
            font-size: 16px;
            color: #333333;
            cursor: pointer;
          }
          .active {
            background: linear-gradient(232deg, #4e78ca, #395da4);
            box-shadow: 0px 3px 6px 0px #a9c6ff;
            color: #ffffff;
          }
        }
        .purchase-main-type-btn-cont {
          margin-top: 20px;
          font-size: 16px;
          color: #333333;
          > p {
            margin-bottom: 6px;
          }
        }
      }
    }
    .purchase-main-buy {
      margin: 20px auto 0 auto;
      width: 280px;
      height: 40px;
      background: linear-gradient(50deg, #b93037, #d44249);
      box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      border-radius: 20px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.purchase {
  .el-dialog {
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: 0 !important;
  }
  .el-dialog__header {
    height: 30px;
    background: #b93138;
    // line-height:20px;
    font-size: 18px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      // line-height:20px;

      .el-dialog__close {
        color: #ffffff;
      }
    }
  }
}
</style>