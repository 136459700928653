<template>
  <div class="product">
    <div class="product-item">
      <div class="label bepaid">金币订单列表</div>
      <div class="table">
        <el-table
          :data="goldOrderList"
          border
          style="width: 100%"
          class-name="elTable"
        >
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="code"
            label="订单号"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="applyTime"
            label="申请日期"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="goldcoin"
            label="金币数量"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="price"
            label="金币价格"
          >
            <template slot-scope="scope">
              {{ scope.row.price ? scope.row.price : '--'}}
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="mealInof.versionName"
            label="套餐名称"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="channleName"
            label="销售终端"
          >
            
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            label="订单状态"
          >
            <template slot-scope="scope">
            <span v-if="!scope.row.channelHandleType || scope.row.channelHandleType == 'apply'"> 
            <span v-if="scope.row.orderStatus == 0 || scope.row.orderStatus == 2">    
                <!-- 垫付订单不需要审核 -->
                <span v-if="scope.row.orderExchangeStatus == -1">待审核</span>
                <span v-if="scope.row.orderExchangeStatus == 2 || scope.row.orderExchangeStatus == 0 ">待付款</span>
                <!-- 退还金币 兑换后园所申请退还金币  需要审核 -->
                <span v-if="scope.row.orderExchangeStatus == 3">退还金币</span>
                <span v-if="scope.row.orderExchangeStatus == 5">已驳回</span>
                <!-- 退还金币 如果没有兑换套餐(1) 不需要审核  只要退还金币就是已完成 销售同意取消(金币退回)4 -->
                <span v-if="scope.row.orderExchangeStatus == 1">
                    已关闭
                </span>
                <span v-if="scope.row.orderExchangeStatus == 4">已关闭</span>
                <!-- 金币退回与系统自动取消的都可以再次申请 -->
                <span v-if="scope.row.orderExchangeStatus == 6">已关闭</span>
            </span>
            <!-- 付款中 -->
            <span v-if="scope.row.orderStatus == 3">付款中</span>
            <!-- 已支付 已支付 未兑换-->
            <span v-if="scope.row.orderStatus == 1">已支付</span>
        </span>
        <!-- 赠送的订单 -->
        <!-- 已支付 不需要立即支付
        没选跟谁签约或者跟平台签约 可以立即支付。 选择和销售签约立即支付置灰 -->
        <span v-if="scope.row.channelHandleType == 'give'" >  
            <!-- <span v-if="{{info.orderExchangeStatus == 0}}">未兑换</span> -->
            <!-- 退还金币已完成 -->
            <span v-if="scope.row.orderExchangeStatus == 1">已关闭</span>
            <span v-if="scope.row.orderStatus == 0 || scope.row.orderStatus == 2">
                <!-- 退还金币 兑换后园所申请退还金币  需要审核 -->
                <span v-if="scope.row.orderExchangeStatus == 3">退还金币</span>
                <span v-if="scope.row.orderExchangeStatus == 5">已驳回</span>
                <span v-if="scope.row.orderExchangeStatus == 4">已关闭</span>
            </span>
            
            <!-- 兑换完成 订单结束 -->
            <span v-if="scope.row.orderStatus == 1">已支付</span>
            <!-- 付款中 -->
            <span v-if="scope.row.orderStatus == 3">付款中</span>

            <span v-if="scope.row.orderStatus == 0 && (scope.row.orderExchangeStatus == 0 || scope.row.orderExchangeStatus == 2 )">待付款</span>
        </span>
              <!-- <span v-if="scope.row.orderStatus == 0">审核中</span>
              <span v-if="scope.row.orderStatus == 1">已到账</span>
              <span v-if="scope.row.orderStatus == 2">已取消</span> -->
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            label="支付方式"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.payType == 'online'">线上支付</span>
              <span v-if="scope.row.payType == 'offline'">线下支付</span>
               <span v-if="scope.row.payType == null">--</span>
            </template>
          </el-table-column>
          
        </el-table>
      </div>
      <comPagination
        :params="goldParams"
        @changePage="changeUpadtePage"
      ></comPagination>
      <div class="label bepaid">套餐订单列表</div>
      <div class="table">
        <el-table
          :data="Orderslist"
          border
          style="width: 100%"
          class-name="elTable"
        >
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="orderNo"
            label="订单号"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            prop="totalPrice"
            label="金币数量"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            prop="realPrice"
            label="金币价格"
          >
          <template slot-scope="scope">
              {{scope.row.schoolId == 894 ? '赠送' : scope.row.realPrice }}
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            prop="versionName"
            label="套餐名称"
          >
          </el-table-column>
          <!-- <el-table-column :resizable="false" prop="realPrice" label="套餐价格">
          </el-table-column> -->
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="idNum"
            label="ID数量"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="orderTime"
            label="兑换日期"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="serviceEndTime"
            label="到期日期"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="serviceEndTime"
            label="订单状态"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.isTry">试用中</span>
            <span v-if="!scope.row.isTry && curDate > scope.row.serviceEndTime1">已失效</span>
            <span v-if="!scope.row.isTry && curDate < scope.row.serviceEndTime1 &&  curDate > scope.row.orderTime1">有效</span>
          </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="serviceEndTime"
            label="签约方式"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.contract.signType == 'system'">和平台签约</span>
            <span v-if="scope.row.contract.signType == 'channel'">和销售签约</span>
          </template>
          </el-table-column>

          <el-table-column :resizable="false" label="合同进度">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.contract.contarctStatus == 0"
                @click="cahkan(scope.row.contract.contractUrl)"
                size="mini"
                type="primary"
              >
                合同查看
              </el-button>
              <span v-else>{{
                scope.row.contract.contarctStatus == 1
                  ? "双方盖章签字"
                  : scope.row.contract.contarctStatus == 2
                  ? "合同邮寄"
                  : "合同完成"
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="h40"></div>
      <div class="description">
        <p class="title">合同流程说明</p>
        <div>
          <img src="../../assets/img/product/Pic_HTLC.png" alt="" />
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "productIndex",
  components: {
    comPagination,
  },
  data() {
    return {
      goldOrderList:[], //金币订单列表
      goldParams: {
        // 分页
        page: 0,
        size: 20,
        total: 0,
      },
      Orderslist:[],
      curDate:''
    };
  },
  created() {
   this.getGoldOrderList();
   this.setMealgetOrders();
   this.curDate = new Date().getTime();
  },
  methods: {
    getGoldOrderList(){
      let params = {
        pageNum:this.goldParams.page,
        pageSize:this.goldParams.size
      };
      this.api.product.applyorderList(params).then((res) => {
        this.goldOrderList = res.data.data;
        this.goldParams.total = res.data.total;
      });
    },
    changeUpadtePage(val) {
      this.goldParams.page = val;
      this.getGoldOrderList();
    },
    setMealgetOrders() {
      this.api.product
        .setMealgetOrders({
          schoolId: JSON.parse(localStorage.getItem("userInfo")).schoolId,
        })
        .then((e) => {
          console.log(e);
          this.Orderslist = e.data;
          this.Orderslist.forEach((item)=>{
            item.serviceEndTime1 = new Date(item.serviceEndTime.replace(/-/g,"/")).getTime();
            item.orderTime1 = new Date(item.orderTime.replace(/-/g,"/")).getTime();
          })
        });
    },
    cahkan(e) {
      window.open("https://oss.prefootball.cn" + e);
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  margin: 0 238px;
}
.dialog_block {
  padding: 20px 0 10px 0;
}
.courseBtn {
  background: none;
  border: none;
  color: #ba3037;
  font-weight: 500;
  cursor: pointer;
}

.label {
  padding: 24px 0;
  font-size: 24px;
  font-weight: bold;
  color: #ba3037;
}
.bepaid {
  color: #333333;
}

/deep/ .el-table th {
  background: #ba3037;
  color: #fff;
  border: none;
  text-align: center;
}

/deep/ .el-table--enable-row-transition .el-table__body td {
  text-align: center;
  border: none;
  border-bottom: 1px solid #dddddd;
  color: #333;
  font-size: 14px;
  line-height: 42px;
}

/deep/ .el-table .operation {
  width: 150px;
}

/deep/ .el-button--mini,
.el-button--small {
  border: none;
  color: #ba3037;
  font-size: 14px;
  background: none;
}

.table {
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
}
.h40 {
  height: 60px;
}
.description {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  margin-bottom: 110px;

  .title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
    // height: 190px;
    margin: 83px auto 0;
    display: block;
  }
}
.table {
  border-radius: 10px;
  overflow: hidden;
}
</style>
