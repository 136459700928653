<template>
  <div class="product">
    <div class="product-tit">
      <div
        :class="currentId == item.id ? 'active' : ''"
        v-for="item in list"
        :key="item.id"
        @click="change(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <Product
      v-if="currentId == 1"
      :productData="productList"
      @goPrice="goPrice"
    ></Product>
    <Price
      v-if="currentId == 2"
      :productData="productList"
      @goOrder="goOrder"
    ></Price>
    <List v-if="currentId == 3"></List>
  </div>
</template>
<script>
import Product from "./product.vue";
import Price from "./price.vue";
import List from "./list.vue";
export default {
  name: "productIndex",
  data() {
    return {
      list: [
        {
          name: "产品",
          id: 1,
        },
        {
          name: "价格",
          id: 2,
        },
        {
          name: "订单",
          id: 3,
        },
      ],
      currentId: 1,
      productList: [],
    };
  },
  components: {
    Product,
    Price,
    List,
  },
  created() {
    if (this.$route.query.type && this.$route.query.type === "class"||this.$route.query.type === "zh") {
      this.currentId = 2;
    } else if (this.$route.query.type && this.$route.query.type === "order") {
      this.currentId = 3;
    }
    this.getProducts();
  },
  methods: {
    change(data) {
      this.currentId = data.id;
    },

    getProducts() {
      this.api.product.getProducts().then((res) => {
        this.productList = res.data;
      });
    },
    goPrice() {
      this.currentId = 2;
      window.scrollTo(0, 0);
    },
    goOrder() {
      this.currentId = 3;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  .product-tit {
    margin: 40px auto 30px auto;
    width: 420px;
    height: 48px;
    line-height: 48px;
    background: #f1f1f1;
    border-radius: 24px;
    font-size: 22px;
    color: #333333;
    @include flex(row, space-between, center);

    > div {
      flex: 1;
      text-align: center;
      cursor: pointer;
    }

    .active {
      width: 140px;
      height: 48px;
      background: #395da4;
      box-shadow: 0px 3px 20px 0px rgba(57, 93, 164, 0.39);
      border-radius: 24px;
      font-weight: bold;
      color: #ffffff;
      font-size: 24px;
    }
  }
}
</style>