<template>
  <div class="price">
    <div class="price-tit">
      {{ num[productList.length - 1] }}大产品业务体系支撑园所数字化综合服务
    </div>
    <div class="price-type">
      <div class="price-type-item" v-for="item in productList" :key="item.id">
        <img v-if="item.iconimg" :src="item.iconimg" alt="" />
        <img v-else src="../../assets/img/product/Icon_KCXL.png" alt="" />
        <p>{{ item.serviceType }}</p>
      </div>
    </div>
    <div class="price-cont">
      <div class="price-cont-tab">
        <div class="price-cont-tab-top">
          <div
            class="price-cont-tab-top-item"
            :class="{ active: curPackage.id == item.id }"
            v-for="(item, index) in packagesList"
            :key="item.id"
            @click="changePackage(item, index)"
          >
            <p>{{ item.packageName }}套餐</p>
            <p>幼儿数: {{ item.idNum }}个</p>
            <p
              class="fgx"
              v-if="
                curPackage.id != item.id &&
                index != packagesList.length - 1 &&
                curIndex != index + 1
              "
            ></p>
            <img
              v-if="curPackage.id == item.id"
              src="../../assets/img/product/Pic_Tab_C.png"
              alt=""
            />
          </div>
        </div>
        <div class="price-cont-tab-cont">
          <div class="price-cont-tab-cont-t">
            <div
              class="price-cont-tab-cont-item"
              :class="{ active: curVersions.id == item.id }"
              v-for="item in curPackage.versions"
              :key="item.id"
              @click="changeVersion(item)"
            >
              <p class="price-cont-tab-cont-item-tit">{{ item.versionName }}</p>
              <p class="price-cont-tab-cont-item-val">
                <!-- <span>¥</span>{{ item.price
                }}<span>/{{ item.serviceMonth }}年</span> -->
                <!-- <img src="../../assets/img/product/Icon_Coin_JG1.png" alt="" />
                {{item.price}}<span>/{{ item.serviceMonth }}年</span> -->
                <span>{{ item.serviceMonth }}年</span>
              </p>
              <!-- <p class="price-cont-tab-cont-item-gold">
                <img src="../../assets/img/product/Icon_Coin_JG.png" alt="" />
                <span>{{ item.price }}金币</span>
              </p> -->
              <!-- <p class="price-cont-tab-cont-item-recommend">推荐</p> -->
            </div>
          </div>
          <div class="price-cont-tab-cont-tis">
            <div>
              <div>金币余额</div>
              <div>仅支持金币支付</div>
            </div>
            <div>
              {{ goldAmount }}
            </div>
            <!-- <span class="tis-txt">仅支持金币支付</span>
            <span class="tis-gold-num"
              >金币余额：<i>{{ goldAmount }}</i></span
            > -->
          </div>
          <!-- <div
            class="price-cont-tab-cont-btn"
            @click="buy"
            v-if="curVersions.price <= goldAmount"
          >
            立即兑换
          </div>
          <div class="price-cont-tab-cont-btn" @click="bindToGold" v-else>
            充值兑换
          </div> -->
          <div class="price-cont-tab-cont-btn" :class="exchangeBtnStatus==1 ? 'notAllow' : ''" @click="goApplet">
            兑换套餐
          </div>
          <div class="price-cont-tab-cont-log">
            <div v-if="latestOrderData">
              <!-- <img src="../../assets/img/product/Icon_TG.png" alt="" />
              <span>{{ latestOrderData }}</span> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="price-cont-des">
        <p class="price-cont-des-tit">套餐服务说明</p>
        <table class="price-cont-des-table">
          <tr class="first">
            <th width="170">产品类型</th>
            <th width="230">服务项目</th>
            <th>内容说明</th>
            <th width="120" v-for="item in versionsList" :key="item.id">
              {{ item.versionName }}
            </th>
          </tr>
          <tr v-for="item in detailList" :key="item.id">
            <td
              :rowspan="item.productLength"
              class="front"
              v-if="item.productName"
            >
              {{ item.productName }}
            </td>
            <td class="front" style="text-align: left">
              {{ item.serviceName }}
            </td>
            <td style="text-align: left">{{ item.content }}</td>
            <td v-for="(sItem, index) in item.version" :key="index">
              <img
                v-if="sItem.status && !(sItem.limitNum > 1)"
                src="../../assets/img/product/Icon_ALL.png"
                alt=""
              />
              <div v-else-if="sItem.status && sItem.limitNum > 1">
                <img
                  src="../../assets/img/product/Icon_Hafl.png"
                  alt=""
                  style="margin-top: 10px"
                />
                <span style="display: block; line-height: 30px">{{
                  sItem.limitNum
                }}</span>
              </div>
              <img v-else src="../../assets/img/product/Icon_None.png" alt="" />
            </td>
          </tr>
          <tr class="last">
            <td class="front1" colspan="3">套餐金币数/年</td>
            <td v-for="item in versionsList" :key="item.id">
              {{ item.price }}
            </td>
          </tr>
        </table>
      </div> -->
    </div>
    <Purchase
      :status="buyStatus"
      :id="curVersions.id"
      @close="closePurchase"
      @paySet="paySet"
    ></Purchase>
    <Contract
      :status="contractStatus"
      :data="postBuyNowData"
      @close="closeContract"
    ></Contract>
    <Wxcode
      :status="codeStatus"
      :price="codePrice"
      :orderId="orderId"
      :codeUrl="codeUrl"
      @close="closeWxcode"
    ></Wxcode>
    <GoldRecharge
      :status="goldStatus"
      :goldCNum="goldNum"
      @close="close"
      @goldPay="bindPay"
      @bindAgreement="bindToAgree"
    />
    <goldAgreementPop
      :status="AgreementStatus"
      @close="closeAgreement"
    ></goldAgreementPop>
    <AppletCode :status="appletStatus" :exchangeBtnStatus="exchangeBtnStatus" @close="closeAppletCode"></AppletCode>
  </div>
</template>
<script>
import Purchase from "./components/purchase.vue";
import Contract from "./components/goldcontract.vue";
//import Wxcode from "./components/wxcode.vue";
// 金币充值弹出框
import GoldRecharge from "@/views/product/components/gold-recharge";
import Wxcode from "@/views/product/components/goldwxcode.vue";
// 金币协议
import goldAgreementPop from "@/views/product/components/goldAgreementPop.vue";
//兑换套餐
import AppletCode from "@/views/product/components/applet.vue";
export default {
  name: "productPrice",
  data() {
    return {
      AgreementStatus: false, // 金币协议
      buyStatus: false,
      contractStatus: false,
      codeStatus: false,
      productList: [],
      num: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
      packagesList: [],
      curPackage: {},
      curVersions: {},
      versionsList: [],
      detailProductList: [],
      detailList: [],
      type: 1,
      postBuyNowData: {},
      createNativeData: {},
      codeUrl: "",
      orderId: "",
      latestOrderData: "",
      curIndex: 0,
      goldAmount: 6400, // 金币数
      goldStatus: false, // 金币弹出框
      goldNum: 0, // 要充值的金币数
      codePrice: 0, // 充值金币
      appletStatus:false, //小程序弹窗状态
      exchangeBtnStatus:null, //兑换按钮状态
    };
  },
  components: {
    Purchase,
    Contract,
    Wxcode,
    GoldRecharge,
    goldAgreementPop,
    AppletCode
  },
  props: {
    productData: {
      typeof: Array,
      default: [],
    },
  },
  watch: {
    productData(newV) {
      this.productList = newV;
    },
    goldNum: {
      handler(newV) {
        console.log(newV, 222222222222);
      },
      deep: true,
    },
  },
  created() {
    this.productList = this.productData;
    this.getPackages();
    this.getGoldApplyInfo();
  },
  mounted() {
    this.getLatestOrder();
    this.getGoldInfo();
  },
  methods: {
    // 获取当前用户金币数
    getGoldInfo() {
      let data = {
        userId: this.$store.state.userInfo.userId,
      };
      this.api.goldcoin.getGoldInfo(data).then((res) => {
        console.log(res.data, "我还有多少金币");
        if (res.code == 20000) {
          this.goldAmount = res.data.goldAmount;
        }
      });
    },
    // 充值金币
    bindToGold() {
      let price = this.curVersions.price - this.goldAmount;
      console.log(price, "需要充值的金币");
      // this.$nextTick(() => {
      //   this.goldNum = price;
      // });
      this.goldStatus = true;
      this.goldNum = price;
    },
    bindPay(val, goldAmount) {
      this.codePrice = Number(goldAmount);
      let data = {
        userId: this.$store.state.userInfo.userId,
        price: Number(goldAmount),
      };
      if (val == 1) {
        this.api.goldcoin.createNative(data).then((res) => {
          if (res.code == 20000) {
            this.close();
            this.codeStatus = true;
            this.codeUrl = res.data.code_url;
            this.orderId = res.data.orderNo;
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      } else {
        this.api.goldcoin.offineRecharge(data).then((res) => {
          console.log(res, "线下支付");
          if (res.code == 20000) {
            this.close();
            this.$notice({
              type: "success",
              message: "请及时上传凭证",
            }).isShow();
            this.$emit("goOrder");
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    buy() {
      this.buyStatus = true;
    },
    closeWxcode(data) {
      this.codeStatus = false;
      this.getGoldInfo();
      if (data) {
        this.$emit("goOrder");
      }
    },
    // 弹起协议窗口
    bindToAgree() {
      this.AgreementStatus = true;
    },
    // 关闭协议
    closeAgreement() {
      this.AgreementStatus = false;
    },
    // 关闭弹出框
    close() {
      this.goldStatus = false;
      this.goldNum = 0;
    },
    paySet() {
      this.buyStatus = false;
      this.contractStatus = true;
      this.postBuyNowData = {
        packageName: this.curPackage.packageName,
        buyIdNum: this.curPackage.idNum,
        versionName: this.curVersions.versionName,
        price: this.curVersions.price,
        buySemesterNum: this.curVersions.serviceMonth,
        schoolId: this.$store.state.schoolInfo.atteSchool.id,
        packageId: this.curVersions.packageId,
        packageVersionId: this.curVersions.id,
      };
    },
    closePurchase() {
      // type:1 微信支付 2:线下支付 3  请款专用
      this.buyStatus = false;
      this.postBuyNowData = {
        packageName: this.curPackage.packageName,
        buyIdNum: this.curPackage.idNum,
        versionName: this.curVersions.versionName,
        price: this.curVersions.price,
        buySemesterNum: this.curVersions.serviceMonth,
        schoolId: this.$store.state.schoolInfo.atteSchool.id,
        packageId: this.curVersions.packageId,
        packageVersionId: this.curVersions.id,
      };
      //this.contractStatus = true;
      // if (data) {
      //   //合同弹窗展示
      //   if (type) {
      //     this.type = type;
      //   }
      //   this.postBuyNowData = {
      //     packageName: this.curPackage.packageName,
      //     buyIdNum: this.curPackage.idNum,
      //     versionName: this.curVersions.versionName,
      //     price: this.curVersions.price,
      //     buySemesterNum: this.curVersions.serviceMonth,
      //     schoolId: this.$store.state.schoolInfo.atteSchool.id,
      //     packageId: this.curVersions.packageId,
      //     packageVersionId: this.curVersions.id,
      //   };
      //   this.contractStatus = true;
      // }
    },
    closeContract() {
      this.contractStatus = false;
      this.getGoldInfo();
      // if (data) {
      //   //合同提交成功 type:1 微信支付 2:线下支付 3  请款专用
      //   if (this.type == 1) {
      //     this.createNativeData = {
      //       ...data,
      //       packageId: this.curPackage.id,
      //       packageVersionId: this.curVersions.id,
      //       price: this.curVersions.price,
      //       schoolId: this.$store.state.schoolInfo.atteSchool.id,
      //       type: this.type - 1,
      //       userId: this.$store.state.userInfo.userId,
      //     };
      //     this.createNative();
      //     this.codeStatus = true;
      //   } else {
      //     //创建订单
      //     this.addOrder(data);
      //   }
      // }
    },
    createNative() {
      this.api.product.createNative(this.createNativeData).then((res) => {
        if (res.flag) {
          this.codeUrl = res.data.code_url;
          this.orderId = res.data.orderId;
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    addOrder(data) {
      //添加订单
      let params = {
        ...data,
        packageId: this.curPackage.id,
        packageVersionId: this.curVersions.id,
        price: this.curVersions.price,
        schoolId: this.$store.state.schoolInfo.atteSchool.id,
        type: this.type - 1,
        userId: this.$store.state.userInfo.userId,
      };
      this.api.product.addOrder(params).then((res) => {
        if (res.flag) {
          if (this.type == 2) {
            this.$notice({
              type: "success",
              message: "请及时上传凭证",
            }).isShow();
          }
          this.$emit("goOrder");
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    getPackages() {
      this.api.product.getPackages().then((res) => {
        this.packagesList = res.data;

        this.curPackage = this.packagesList[2];
        this.curVersions = this.packagesList[2].versions[0];

        this.getPackageDetail();
      });
    },
    getPackageDetail() {
      let params = {
        packageId: this.curPackage.id,
      };
      this.api.product.getPackageDetail(params).then((res) => {
        // this.packagesList = res.data;
        this.detailProductList = res.data.products;
        this.versionsList = res.data.versions;

        this.formateData();
      });
    },
    formateData() {
      this.detailList = [];
      this.detailProductList.forEach((item) => {
        item.services.length &&
          item.services.forEach((sItem, index) => {
            this.$set(sItem, "version", []);
            this.versionsList.forEach((vItem) => {
              if (vItem.serviceVersions.length) {
                let obj = vItem.serviceVersions.filter((svItem) => {
                  return svItem.service.id == sItem.id;
                });
                if (obj.length) {
                  let obj1 = {
                    status: true,
                  };
                  if (obj[0].serviceNum);
                  {
                    obj1.limitNum = obj[0].serviceNum;
                  }
                  sItem.version.push(obj1);
                } else {
                  let obj1 = {
                    status: false,
                  };
                  sItem.version.push(obj1);
                }
              }
            });
            if (index == 0) {
              this.$set(sItem, "productName", item.serviceType);
              this.$set(sItem, "productLength", item.services.length);
            }
            this.detailList.push(sItem);
          });
      });
    },
    changePackage(data, index) {
      this.curPackage = data;
      this.curIndex = index;
      this.curVersions = this.curPackage.versions[0];

      this.getPackageDetail();
    },
    changeVersion(data) {
      console.log(data);
      this.curVersions = data;
    },
    getLatestOrder() {
      this.api.product.getLatestOrder().then((res) => {
        this.latestOrderData = res.data.info;
      });
    },
    goApplet(){ //打开小程序弹窗
      if(this.exchangeBtnStatus != 1){
        if(this.$store.state.userInfo.teacherId){
          this.$notice({
            message: '请使用园长账号兑换套餐～',
          }).isShow();
        }else{
          this.appletStatus = true;
        }
      }
    },
    closeAppletCode(){  //关闭小程序弹窗
      this.appletStatus = false;
    },
    getGoldApplyInfo(){ //获取金币详情
      let params = {
          schoolId:this.$store.state.schoolInfo.atteSchool.id,
      }
      this.api.product.getGoldApplyInfo(params).then((res) => {
          console.log(res,'ddd')
          //data: 1存在有效套餐 2有金币未支付3已支付未兑换4没有金币
          this.exchangeBtnStatus = res.data;
      });
    }
  },
};
</script>
<style scoped lang="scss">
.price {
  .price-tit {
    margin: 52px auto 54px auto;
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .price-type {
    @include flex(row, space-around, center);
    width: 1440px;
    margin: 0 auto 70px auto;
    .price-type-item {
      width: 140px;
      text-align: center;
      font-size: 28px;
      color: #333333;
      > img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
    }
  }
  .price-cont {
    background: #f1f1f1;

    .price-cont-tab {
      width: 1443px;
      margin: 0 auto;
      padding-top: 40px;
      .price-cont-tab-top {
        font-size: 24px;
        color: #333333;
        height: 124px;
        @include flex(row, flex-start, center);

        .price-cont-tab-top-item {
          flex: 1;
          text-align: center;
          height: 100%;
          cursor: pointer;
          position: relative;
          > img {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -82px;
            width: 164px;
            height: 6px;
          }
          > p:nth-child(1) {
            font-size: 36px;
            font-weight: bold;
            color: #333333;
            margin: 24px 0 0px 0;
          }
          .fgx {
            position: absolute;
            right: 0;
            top: 28px;
            width: 1px;
            height: 68px;
            background: #cccccc;
          }
        }
        .active {
          background: #ffffff;
          border-radius: 16px 16px 0 0;
        }
      }
      .price-cont-tab-cont {
        background: #ffffff;
        border-radius: 0px 0px 20px 20px;

        .price-cont-tab-cont-t {
          @include flex(row, flex-start, center);
        }

        .price-cont-tab-cont-item {
          flex: 1;
          margin: 92px 40px 36px 40px;
          width: 401px;
          // height: 282px;
          border: 2px solid #dddddd;
          border-radius: 16px;
          text-align: center;
          position: relative;
          cursor: pointer;
          padding: 20px;
          box-sizing: border-box;
          .price-cont-tab-cont-item-tit {
            margin-top: 20px;
            font-size: 28px;
            font-weight: bold;
            color: #333333;
          }
          .price-cont-tab-cont-item-val {
            margin-top: 20px;
            padding-bottom: 20px;
            // border-bottom: 1px solid #dddddd;
            font-size: 64px;
            font-weight: bold;
            color: #f43841;
            // > span:nth-child(1) {
            //   font-size: 36px;
            //   font-weight: bold;
            //   color: #f43841;
            // }
            >img{
              width: 56px;
              height: 56px;
              margin-top: -14px;
              margin-right: -10px;
            }
            > span {
              font-size: 30px;
              font-weight: 400;
              color: #999999;
            }
          }
          .price-cont-tab-cont-item-gold {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-top: 17px;
            img {
              width: 40px;
              height: 42px;
              margin-right: 12px;
            }
          }
          .price-cont-tab-cont-item-recommend {
            position: absolute;
            left: -1px;
            top: -20px;
            background: url("../../assets/img/product/Pic_Bg_TJ.png") no-repeat;
            background-size: 98px 40px;
            font-size: 24px;
            color: #ffffff;
            text-align: center;
            line-height: 40px;
            width: 98px;
            height: 40px;
          }
        }
        .active {
          background: #fceeed;
          border: 3px solid #e64e55;
          box-shadow: 0px 0px 10px 0px #ffd7d9;
        }
        .price-cont-tab-cont-tis {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f43841;
          >div{
            text-align: right;
          }
          >div:nth-child(1){
            margin-right: 14px;
            
          }
          >div:nth-child(1) >div:nth-child(1){
            color: #888888;
          }
          >div:nth-child(2){
            font-size: 42px;
            font-weight: bold;
            color: #333333;
          }
          // .tis-gold-num {
          //   color: #888888;
          //   margin-left: 30px;
          //   i {
          //     color: #333333;
          //     font-style: normal;
          //   }
          // }
        }
        .price-cont-tab-cont-btn {
          margin: 0 auto;
          width: 376px;
          height: 120px;
          background: url("../../assets/img/product/BtnLJGM1.png") no-repeat;
          background-size: cover;
          line-height: 120px;
          text-align: center;
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
        }
        .price-cont-tab-cont-btn.notAllow{
          background: url("../../assets/img/product/Btn_Bg_Dh_Gray.png") no-repeat;
          background-size: cover;
        }
        .price-cont-tab-cont-log {
          margin-top: 24px;
          padding-bottom: 28px;
          @include flex(row, center, center);
          > div {
            font-size: 18px;
            color: #333333;
            @include flex(row, center, center);
            > img {
              margin-right: 11px;
            }
          }
        }
      }
    }

    .price-cont-des-tit {
      margin-top: 60px;
      padding-bottom: 28px;
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #333333;
    }
    .price-cont-des-table {
      margin: 0 auto;
      width: 1443px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-bottom: 0;
      border-right: 0;
      border-radius: 4px;
      color: #333333;
      font-size: 16px;
      line-height: 28px;
      .first {
        background: #eef7fe;
        font-size: 20px;
        font-weight: bold;
        color: #2f5094;
      }
      .front {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .front1 {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
      .last {
        background: #2f5094;
        font-size: 20px;
        font-weight: bold;
        color: #ffd445;
      }

      > tr {
        height: 70px;
        border-bottom: 1px solid #dddddd;

        > td,
        > th {
          border-right: 1px solid #dddddd;
          text-align: center;
          padding: 0 10px;
        }
      }
    }
  }
}
</style>